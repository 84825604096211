// Test url: https://link-test.thimble.com/policy/pdf/6513b53a976a210037b4a744/Purchase

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import _ from 'lodash';
import withSSR from '../../components/withSSR';

class PolicyPdfPage extends Component {
  static async getInitialData({ match, req, res }) {
    const options = {
      headers: {
        'X-API': 'api-policy',
      },
    };

    if (match.params.id) {
      const response = await fetch(
        `${process.env.RAZZLE_API_POLICY}/policy/${match.params.id}`,
        options
      );

      if (response.status !== 200) {
        throw new Error('Policy Error');
      }

      let pdf_url;

      const json = await response.json();

      _.forEach(json.documents, (item) => {
        if (item.id === match.params.pdfid) {
          pdf_url = item.pdf_url;
        }
      });

      _.forEach(json.additional_insured, (item) => {
        if (item.id === match.params.pdfid) {
          pdf_url = item.verifly_pdf_url;
        }
      });

      const imPlan = _.find(json.plan_list, (plan) => plan.insurance_type === 'im') || {};
      _.forEach(imPlan.loss_payee_items, (item) => {
        if (item.uniq_id === match.params.pdfid) {
          pdf_url = item.verifly_pdf_url;
        }
      });

      _.forEach(json.coverage_intervals, (item) => {
        if (item.id === match.params.pdfid) {
          pdf_url = item.verifly_pdf_url;
        }
      });

      _.forEach(json.certificate_holders, (item) => {
        if (item.id === match.params.pdfid) {
          pdf_url = item.pdf_url;
        }
      });

      if (_.isUndefined(pdf_url)) {
        throw new Error('PDF ID Error');
      }

      if (_.isEmpty(pdf_url)) {
        throw new Error('PDF Not Generated');
      }

      if (pdf_url.includes(req.path)) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      return {
        url: pdf_url,
      };
    }
  }

  render() {
    return <Redirect to={this.props.url} />;
  }
}

export default withSSR(PolicyPdfPage);

// Test url: https://link-test.thimble.com/broker/P359RLWN7

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { get, isEmpty } from 'lodash';
import { createLogger } from 'browser-bunyan';
import withSSR from '../../components/withSSR';

const customStream = {
  write: (obj) => {
    console.log(JSON.stringify(obj));
  },
};

const logger = createLogger({
  name: 'web-user-portal',
  stream: customStream,
});

class BrokerReferralPage extends Component {
  static async getInitialData({ match, req, res }) {
    const veriflyId = get(match.params, 'id', '');
    if (isEmpty(veriflyId.trim())) {
      logger.error(`BrokerReferralPage: no verifly id found, reqUrl: ${req.url}`);
      return {
        url: `${process.env.RAZZLE_WEBAPP_DOMAIN}`,
      };
    }

    const reqOpts = {
      method: 'POST',
      headers: {
        'X-API': 'web-user-portal',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        verifly_id: veriflyId,
      }),
    };

    let queryString = `?brokerid=${veriflyId}&utm_campaign=inviter:${veriflyId}&utm_source=broker&utm_medium=referral-link`;
    try {
      const response = await fetch(
        `${process.env.RAZZLE_API_BROKER}/broker/find-by-verifly-id`,
        reqOpts
      );
      if (response.status !== 200) {
        throw new Error('find-by-verifly-id error');
      }

      const broker = await response.json();
      if (broker.is_terminated) {
        // for terminated brokers, we just redirect to webapp
        logger.warn(
          `BrokerReferralPage: broker: ${veriflyId} is terminated, redirect without params, reqUrl: ${req.url}`
        );
        queryString = `?utm_source=broker&utm_medium=referral-link&utm_campaign=terminated-${veriflyId}`;
      }
    } catch (err) {
      logger.error(
        `BrokerReferralPage: failed to find by verifly id: ${veriflyId}, reqUrl: ${req.url}`,
        err
      );
    }

    return {
      url: `${process.env.RAZZLE_WEBAPP_DOMAIN}${queryString}`,
    };
  }

  render() {
    return <Redirect to={this.props.url} />;
  }
}

export default withSSR(BrokerReferralPage);

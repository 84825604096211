// Test url: https://link-test.thimble.com/support/tickets/new?x=1&y=2

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { isEmpty, includes } from 'lodash';
import { createLogger } from 'browser-bunyan';
import withSSR from '../../components/withSSR';
import fleshDeskLinkMappingData from '../../data/fleshdesklinkmapping.json';

const customStream = {
  write: (obj) => {
    console.log(JSON.stringify(obj));
  },
};

const logger = createLogger({
  name: 'web-user-portal',
  stream: customStream,
});

function mapNewLink(subLink) {
  const matchList = new RegExp(/\d{11}/).exec(subLink);
  if (!isEmpty(matchList)) {
    const articleId = matchList[0];
    const newSubLink = fleshDeskLinkMappingData[articleId];
    if (newSubLink) {
      return `${process.env.RAZZLE_SUPPORT_DOMAIN}/${newSubLink}`;
    }
  }

  return null;
}

class FleshDeskLinkMappingPage extends Component {
  static async getInitialData({ match, req, res }) {
    const subLink = req.url;
    let url = `${process.env.RAZZLE_SUPPORT_DOMAIN}/hc/en-us`;
    try {
      if (includes(subLink, '/support/home')) {
        url = `${process.env.RAZZLE_SUPPORT_DOMAIN}/hc/en-us`;
      } else if (includes(subLink, '/support/tickets/new')) {
        url = `${process.env.RAZZLE_SUPPORT_DOMAIN}/hc/en-us/requests/new`;
      } else {
        const newUrl = mapNewLink(subLink);

        if (!newUrl) {
          logger.info(`404 for link -> ${subLink}`);
        }

        url = newUrl || url;
      }

      const queryStr = subLink.split('?');
      if (queryStr && queryStr[1]) {
        url = `${url}?${queryStr[1]}`;
      }
    } catch (err) {
      logger.error(err);
    }

    return {
      url,
    };
  }

  render() {
    return <Redirect to={this.props.url} />;
  }
}

export default withSSR(FleshDeskLinkMappingPage);

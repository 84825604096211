// Test url: https://portal-test.thimble.com/pdf?session_token=0d8f1b43-3f3d-40d8-85fe-3dbaff678a94&appkey=mobile@webapp&template_type=Acord25Preview&for_ai=N&coverage_timezone=America%2FLos_Angeles&coverage_start_timestamp=1699435200&coverage_end_timestamp=1731057600&coverage_exposure_end_timestamp=1762593600&coverage_available_amount=1000000&quote_id=b7c69308-b39f-4d96-ade9-1a411b449449

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import withSSR from '../../components/withSSR';

class PdfPage extends Component {
  static async getInitialData({ match, req, res }) {
    if (req.query && req.query.template_type) {
      const options = {
        method: 'POST',
        headers: {
          'session-token': req.query.session_token,
          'appkey': 'tsmb@userportal',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          template_type: req.query.template_type,
          text_params: req.query,
        }),
      };

      const response = await fetch(`${process.env.RAZZLE_API_MOBILE}/pdf`, options);

      const json = await response.json();
      if (response.status !== 200) {
        throw new Error('Pdf Error');
      }

      return {
        url: `https://pdf-viewer.thimble.com/viewer.html?file=${json.pdf_url}`,
      };
    }
  }

  render() {
    return <Redirect to={this.props.url} />;
  }
}

export default withSSR(PdfPage);

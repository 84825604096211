// Test url: https://link-test.thimble.com/u/some_id?utm_campaign=some-utm-campaign

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { isEmpty } from 'lodash';
import withSSR from '../../components/withSSR';

class UserSharePage extends Component {
  static async getInitialData({ match, req, res }) {
    if (match.params.id) {
      let utmCampaign = match.params.id;
      if (!isEmpty(req.query.utm_campaign)) {
        utmCampaign = req.query.utm_campaign;
      }
      const utmParams = `utm_campaign=${utmCampaign}&utm_source=share&utm_medium=webapp`;
      return {
        url: `${process.env.RAZZLE_WEBAPP_DOMAIN}?${utmParams}`,
      };
    }
  }

  render() {
    return <Redirect to={this.props.url} />;
  }
}

export default withSSR(UserSharePage);

// Test url: https://link-test.thimble.com/drone-link/menu-fineprint

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import withSSR from '../../components/withSSR';

class LinkPage extends Component {
  static async getInitialData({ match, req, res }) {
    if (match.params.name) {
      let url;

      switch (match.params.name) {
        case 'menu-fineprint':
          url = 'https://support.verifly.com/support/solutions/folders/42000073946';
          break;
        case 'menu-privacy':
          url = 'https://support.verifly.com/support/solutions/articles/42000015647';
          break;
        case 'terms-conditions':
          url = 'https://support.verifly.com/support/solutions/articles/42000015641';
          break;
        case 'menu-claim':
          url =
            'https://support.verifly.com/support/solutions/articles/42000015646-drone-how-do-i-report-a-drone-aviation-claim-';
          break;
        case 'menu-faq':
          url = 'https://support.verifly.com/support/solutions/42000048682';
          break;
        case 'menu-feedback':
          url =
            'https://support.verifly.com/support/solutions/articles/42000015681-how-do-i-get-technical-support-or-give-feedback-';
          break;
        default:
          break;
      }

      return {
        url,
      };
    }
  }

  render() {
    return <Redirect to={this.props.url} />;
  }
}

export default withSSR(LinkPage);

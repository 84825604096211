// Test url: https://link-test.thimble.com/partner/PKS8PY5KW

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import withSSR from '../../components/withSSR';

class PartnerReferralPage extends Component {
  static async getInitialData({ match, req, res }) {
    if (match.params.id) {
      const utmParams = `utm_campaign=inviter:${match.params.id}&utm_source=partner`;
      return {
        url: `${process.env.RAZZLE_WEBAPP_DOMAIN}?partnerid=${match.params.id}&${utmParams}`,
      };
    }
  }

  render() {
    return <Redirect to={this.props.url} />;
  }
}

export default withSSR(PartnerReferralPage);

// Test url: https://link-test.thimble.com/broker/flyer/customer/64dbf6d3e7e35b0037b952d0

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import withSSR from '../../components/withSSR';

class BrokerFlyerPdfPage extends Component {
  static async getInitialData({ match, req, res }) {
    const brokerId = match.params.id;
    let options = {
      method: 'GET',
      headers: {
        'X-API': 'api-user',
      },
    };
    let response = await fetch(`${process.env.RAZZLE_API_BROKER}/broker/${brokerId}`, options);
    if (response.status !== 200) {
      throw new Error('Broker Error');
    }

    const broker = await response.json();
    let broker_flyer_pdf_url;
    if (broker.customer_flyer_location) {
      broker_flyer_pdf_url = broker.customer_flyer_url;
    } else {
      options = {
        method: 'POST',
        headers: {
          'X-API': 'api-referral',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          filter: {
            instance_type: 'broker',
            instance_id: brokerId,
          },
          sort: {
            create_date_time: 1,
          },
          start_index: 0,
          page_number: 1,
        }),
      };

      response = await fetch(`${process.env.RAZZLE_API_REFERRAL}/inviter-search`, options);
      if (response.status !== 200) {
        throw new Error('Broker Referral Error');
      }

      const brokerInviters = await response.json();
      if (brokerInviters.count < 1) {
        throw new Error('Broker Inviter Not Found');
      }

      const brokerVeriflyId = brokerInviters.results[0].verifly_id;
      const referralLink = brokerInviters.results[0].webapp_link;
      options = {
        method: 'POST',
        headers: {
          'X-API': 'api-broker',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          broker_inviter_id: brokerVeriflyId,
          template_type: 'BrokerFlyer',
          pdf_params: {
            text_params: {
              ReferralLink: referralLink,
            },
            link_params: [
              {
                fieldName: 'ReferralLink',
                url: referralLink,
              },
            ],
          },
        }),
      };
      response = await fetch(`${process.env.RAZZLE_API_USER}/pdf`, options);

      if (response.status !== 200) {
        throw new Error('Pdf Error');
      }

      const pdf = await response.json();
      broker_flyer_pdf_url = pdf.pdf_url;
      options = {
        method: 'PUT',
        headers: {
          'X-API': 'api-broker',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customer_flyer_location: pdf.s3_key,
          broker_id: brokerId,
        }),
      };
      response = await fetch(`${process.env.RAZZLE_API_BROKER}/broker`, options);
      if (response.status !== 200) {
        throw new Error('Broker Update S3key Error');
      }
    }

    return {
      url: broker_flyer_pdf_url,
    };
  }

  render() {
    return <Redirect to={this.props.url} />;
  }
}

export default withSSR(BrokerFlyerPdfPage);

import { BrowserRouter, Route } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import routes from './routes';
import App from './containers/App/App';
import withTracker from './components/withTracker';

const data = window._INITIAL_DATA_;

hydrate(
  <BrowserRouter>
    <Route component={withTracker(App, { routes, initialData: data })} />
  </BrowserRouter>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}

import PolicyStatusPage from './containers/Page/PolicyStatusPage';
import PolicyPdfPage from './containers/Page/PolicyPdfPage';
import LinkPage from './containers/Page/LinkPage';
import DroneLinkPage from './containers/Page/DroneLinkPage';
import PdfPage from './containers/Page/PdfPage';
import BrokerReferralPage from './containers/Page/BrokerReferralPage';
import UserSharePage from './containers/Page/UserSharePage';
import PartnerReferralPage from './containers/Page/PartnerReferralPage';
import UserReferralPage from './containers/Page/UserReferralPage';
import ExtPassPaymentPage from './containers/Page/ExtPassPaymentPage';
import UserReferralEmailPage from './containers/Page/UserReferralEmailPage';
import BrokerFlyerPdfPage from './containers/Page/BrokerFlyerPdfPage';
import FleshDeskLinkMappingPage from './containers/Page/FleshDeskLinkMappingPage';
import ExtPassPolicyDetailPage from './containers/Page/ExtPassPolicyDetailPage';

// This is a static route configuration. It should include all of your top level
// routes, regardless of whether they are going to server render. In fact, you
// can totally point multiple routes to the same component! This is great for
// when you only need to server render a handful of routes and not your entire
// application!
const routes = [
  {
    path: '/policy/status/:id',
    component: PolicyStatusPage,
    name: 'PolicyStatus',
  },
  {
    path: '/policy/pdf/:id/:pdfid',
    component: PolicyPdfPage,
    name: 'PolicyPdf',
  },
  {
    path: '/smb-link/:name',
    component: LinkPage,
    name: 'Link',
  },
  {
    path: '/drone-link/:name',
    component: DroneLinkPage,
    name: 'DroneLink',
  },
  {
    path: '/support/*',
    component: FleshDeskLinkMappingPage,
    name: 'FleshDeskLinkMapping',
  },
  {
    path: '/pdf',
    component: PdfPage,
    name: 'Pdf',
  },
  {
    path: '/broker/flyer/customer/:id',
    component: BrokerFlyerPdfPage,
    name: 'BrokerFlyerPdf',
  },
  {
    path: '/broker/:id',
    component: BrokerReferralPage,
    name: 'BrokerReferral',
  },
  {
    path: '/u/:id',
    component: UserSharePage,
    name: 'UserShare',
  },
  {
    path: '/partner/:id',
    component: PartnerReferralPage,
    name: 'PartnerReferral',
  },
  {
    path: '/user/referral/:id',
    component: UserReferralEmailPage,
    name: 'UserReferralEmailPage',
  },
  {
    path: '/user/:id',
    component: UserReferralPage,
    name: 'UserReferral',
  },
  {
    path: '/ep/:token_no',
    component: ExtPassPaymentPage,
    name: 'ExtPassPayment',
  },
  {
    path: '/pdp/:token_no',
    component: ExtPassPolicyDetailPage,
    name: 'ExtPassPolicyDetail',
  },
];

export default routes;

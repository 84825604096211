// Test url: https://link-test.thimble.com/user/referral/574a67edbdefd5c832000699

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { isEmpty } from 'lodash';
import withSSR from '../../components/withSSR';

class UserReferralEmailPage extends Component {
  static async getInitialData({ match, req, res }) {
    if (match.params.id) {
      const userId = match.params.id;
      console.log(userId);
      let options = {
        method: 'GET',
        headers: {
          'X-API': 'api-user',
        },
      };
      let response = await fetch(`${process.env.RAZZLE_API_USER}/user/${userId}`, options);
      if (response.status !== 200) {
        throw new Error('User Not Found');
      }

      const user = await response.json();
      if (!user.feature_customer_referral) {
        let utmCampaign = user.verifly_id;
        if (!isEmpty(req.query.utm_campaign)) {
          utmCampaign = req.query.utm_campaign;
        }
        const utmParams = `utm_campaign=${utmCampaign}&utm_source=share&utm_medium=webapp`;
        return {
          url: `${process.env.RAZZLE_WEBAPP_DOMAIN}?${utmParams}`,
        };
      }

      options = {
        method: 'POST',
        headers: {
          'X-API': 'api-referral',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          filter: {
            instance_id: userId,
          },
        }),
      };

      response = await fetch(`${process.env.RAZZLE_API_REFERRAL}/inviter-search`, options);
      if (response.status !== 200) {
        throw new Error('User Referral Error');
      }

      let inviters = await response.json();
      let inviterVeriflyId;
      if (inviters.count < 1) {
        options = {
          method: 'POST',
          headers: {
            'X-API': 'api-user',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userId,
          }),
        };
        response = await fetch(`${process.env.RAZZLE_API_USER}/user/referral-enable`, options);
        if (response.status !== 200) {
          throw new Error('Create inviter Error');
        }

        const inviter = await response.json();
        inviterVeriflyId = inviter.verifly_id;
      } else {
        inviterVeriflyId = inviters.results[0].verifly_id;
      }

      let utmCampaign = `inviter:${inviterVeriflyId}`;
      if (!isEmpty(req.query.utm_campaign)) {
        utmCampaign = req.query.utm_campaign;
      }
      const utmParams = `utm_campaign=${utmCampaign}&utm_source=user`;
      const inviterParams = `inviterid=${inviterVeriflyId}&invitertype=user`;
      return {
        url: `${process.env.RAZZLE_WEBSITE_DOMAIN}/?${inviterParams}&${utmParams}`,
      };
    }
  }

  render() {
    return <Redirect to={this.props.url} />;
  }
}

export default withSSR(UserReferralEmailPage);

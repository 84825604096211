import React, { Component } from 'react';
import GoogleAnalytics from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

GoogleAnalytics.initialize(process.env.RAZZLE_GA_ID);
ReactPixel.init(process.env.RAZZLE_FB_ID);

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    GoogleAnalytics.set({
      page,
    });
    GoogleAnalytics.pageview(page);
    ReactPixel.pageView();
  };

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...options} />;
    }
  };

  return HOC;
};

export default withTracker;

// Test url: https://link-test.thimble.com/user/P39ZA47CM

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { isEmpty } from 'lodash';
import withSSR from '../../components/withSSR';

class UserReferralPage extends Component {
  static async getInitialData({ match, req, res }) {
    if (match.params.id) {
      let utmCampaign = `inviter:${match.params.id}`;
      if (!isEmpty(req.query.utm_campaign)) {
        utmCampaign = req.query.utm_campaign;
      }
      const utmParams = `utm_campaign=${utmCampaign}&utm_source=user`;
      const inviterParams = `inviterid=${match.params.id}&invitertype=user`;
      return {
        url: `${process.env.RAZZLE_WEBSITE_DOMAIN}/?${inviterParams}&${utmParams}`,
      };
    }
  }

  render() {
    return <Redirect to={this.props.url} />;
  }
}

export default withSSR(UserReferralPage);

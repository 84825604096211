import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import './PolicyStatus.css';

class PolicyStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //<!-- 切换class来更换底色，分别是active,cancelled,completed,withdrawn -->
  render() {
    return (
      <div className={this.props.policy.app_type === 'smb' ? 'smb-wrap' : 'drone-wrap'}>
        {this.props.policy.app_type === 'smb' ? (
          <header className="smb-header">
            <div className="logo-box">
              <span className="logo-spin">
                <img src="/images/thimble.gif" alt="Thimble" />
              </span>
              <span className="logo-text">
                <img src="/images/thimble_text.svg" alt="Thimble" />
              </span>
            </div>
          </header>
        ) : (
          <header className="header">
            <span className="logo" />
          </header>
        )}
        <div
          id="status"
          className={
            this.props.policy.is_expired_annual_renew_parent_policy
              ? 'status-box annual-renew'
              : `status-box ${this.props.policy.status}`
          }
        >
          <div className="box-inner">
            <div className="status-text small">Status</div>
            {this.props.policy.is_expired_annual_renew_parent_policy ? (
              <div id="status-text" className="status-text small">
                This policy {this.props.policy.policy_no} has been renewed. To view your current
                documents,{' '}
                <a className="about-link" href={this.props.policy.child_policy_status_link}>
                  click here
                </a>
                .
              </div>
            ) : (
              <div id="status-text" className="status-text large">
                {this.props.policy.status_text}
              </div>
            )}
          </div>
        </div>
        <div className="validation-box">
          <div className="box-inner">
            <dl className="validation-list">
              {!isEmpty(this.props.policy.applicant_name) && <dt>Insured</dt>}
              {!isEmpty(this.props.policy.applicant_name) && (
                <dd>{this.props.policy.applicant_name}</dd>
              )}
              {!isEmpty(this.props.policy.company_name) && (
                <dd>{this.props.policy.company_name}</dd>
              )}
              {!isEmpty(this.props.policy.carrier_name) && <dt>Insurer</dt>}
              {!isEmpty(this.props.policy.carrier_name) && (
                <dd>{this.props.policy.carrier_name}</dd>
              )}
              <dt>Policy Number</dt>
              <dd id="policy-number">{this.props.policy.policy_no}</dd>
              <dt>Coverage Type</dt>
              <dd>{this.props.policy.plan_name}</dd>
              <dt>Limit</dt>
              <dd>{this.props.policy.coverage_limit}</dd>
              <dt>Starts</dt>
              <dd className="has-icon">
                <span className="data-icon" />
                <span id="start-date">{this.props.policy.start_date_time}</span>
              </dd>
              <dt>Ends</dt>
              <dd className="has-icon">
                <span className="data-icon" />
                <span id="end-date">{this.props.policy.end_date_time}</span>
              </dd>
              {this.props.policy.status === 'cancelled' && <dt>Cancellation Time</dt>}
              {this.props.policy.status === 'cancelled' && (
                <dd className="has-icon">
                  <span className="data-icon" />
                  <span id="cancel-date">{this.props.policy.cancel_date_time}</span>
                </dd>
              )}
            </dl>
            <p>Current as of {this.props.policy.now_date_time}</p>
          </div>
        </div>
        {this.props.policy.app_type === 'smb' ? (
          <div className="about-box">
            <div className="box-inner">
              <p>
                Verifly Insurance Services, Inc. d/b/a Thimble Insurance Services ("Thimble") is
                licensed as an insurance producer in the states where it offers insurance products
                on this website/app for sale. However, the insurance products on this website/app
                may not currently be available in all states including in the states where the
                insurance products have not been approved for use by the applicable insurance
                regulators. Thimble makes no representation that the insurance products on this
                website/app are appropriate or available for use in any particular jurisdiction. If
                you access this website/app from a jurisdiction where the insurance products
                generally or any insurance product specifically are not being offered for sale, you
                hereby acknowledge and agree that you are viewing this website/app for general
                informational purposes only.
              </p>
              <p>
                All insurance products administered by Thimble are subject to the terms and
                conditions of the insurance policies and all other documentation for such insurance
                products. Eligibility, pricing and coverage may vary by state, activity / class and
                other factors. Please be advised that Thimble cannot add, delete, or modify coverage
                via email. THE INSURANCE PRODUCTS PROVIDED ON THE THIMBLE PLATFORM AND VIA THE
                SERVICES ARE UNDERWRITTEN BY MARKEL INSURANCE COMPANY, EXCEPT FOR DRONE INSURANCE
                PRODUCTS WHICH ARE UNDERWRITTEN BY ONE OR MORE OF THE OTHER INSURANCE COMPANIES{' '}
                <a
                  className="about-link"
                  href="https://support.thimble.com/support/solutions/articles/42000022702"
                >
                  LISTED HERE
                </a>
                .
              </p>
            </div>
          </div>
        ) : (
          <div className="about-box">
            <div className="box-inner">
              <p>
                Verifly Insurance Services, Inc. is licensed as an insurance producer in the states
                where it offers insurance products on this website/app for sale. However, the
                insurance products on this website/app may not currently be available in all states
                including in the states where the insurance products have not been approved for use
                by the applicable insurance regulators. Verifly makes no representation that the
                insurance products on this website/app are appropriate or available for use in any
                particular jurisdiction. If you access this website/app from a jurisdiction where
                the insurance products generally or any insurance product specifically are not being
                offered for sale, you hereby acknowledge and agree that you are viewing this
                website/app for general informational purposes only. All insurance products are
                subject to the terms and conditions of the insurance policies and all other
                documentation for such insurance products. Please be advised that we cannot add,
                delete, or modify coverage via email.
              </p>
              <p>
                Verifly Insurance Services,
                <br />
                174 West 4th Street, Suite 204
                <br />
                New York, NY 10014
                <br />
                <a
                  className="about-link"
                  href="https://verifly.freshdesk.com/support/solutions/articles/42000015641"
                >
                  Terms and Conditions
                </a>{' '}
                |{' '}
                <a
                  className="about-link"
                  href="https://verifly.freshdesk.com/support/solutions/articles/42000015647"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PolicyStatus;

// Test url: https://portal-test.thimble.com/policy/status/IBL-F3MK3RWBD7

import React, { Component } from 'react';
import moment from 'moment-timezone';
import { get, includes } from 'lodash';
import withSSR from '../../components/withSSR';
import PolicyStatus from '../Policy/PolicyStatus';
import PolicyHelper from '../Policy/PolicyHelper';

class PolicyStatusPage extends Component {
  static async getInitialData({ match, req, res }) {
    const options = {
      headers: {
        'X-API': 'api-policy',
      },
    };

    let policyno;
    if (match.params.id) {
      try {
        policyno = match.params.id.slice(0, -1);
        const crcStr = PolicyHelper.checksum(policyno);
        const crc = match.params.id.slice(-1);

        if (crc !== crcStr) {
          throw new Error('Policy Error');
        }

        const response = await fetch(
          `${process.env.RAZZLE_API_POLICY}/policy-no/${policyno}`,
          options
        );

        if (response.status !== 200) {
          throw new Error('Policy Error');
        }

        const json = await response.json();

        if (json.policy_no !== policyno) {
          throw new Error('Policy Error');
        }

        const policy = PolicyHelper.convert(json);

        // annual renew and upgrade carrier will show child policy status pdf
        const childPolicyId = json.child_policy_id || get(json.upgrade_carrier, 'child_policy_id');
        if (policy.status_text === 'Expired' && childPolicyId) {
          const childResponse = await fetch(
            `${process.env.RAZZLE_API_POLICY}/policy/${childPolicyId}`,
            options
          );
          const childJson = await childResponse.json();
          if (includes(['paid', 'approved', 'prebook'], childJson.policy_status)) {
            policy.is_expired_annual_renew_parent_policy = true;
            policy.child_policy_no = childJson.policy_no;
            const crcChildStr = PolicyHelper.checksum(childJson.policy_no);
            policy.child_policy_status_link = `${process.env.RAZZLE_USER_PORTAL}/policy/status/${childJson.policy_no}${crcChildStr}?utm_source=pdf`;
          }
        }

        return {
          policy,
        };
      } catch (err) {
        return {
          policy: {
            policy_no: policyno,
            app_type: 'smb',
            plan_name: 'N/A',
            status: 'nofound',
            status_text: 'Policy Not Found',
            coverage_limit: 'N/A',
            timezone: '',
            start_date_time: 'N/A',
            end_date_time: 'N/A',
            now_date_time: moment().tz('America/New_York').format('M/D/YYYY hh:mm A z'),
          },
        };
      }
    }
  }

  render() {
    return <PolicyStatus policy={this.props.policy} />;
  }
}

export default withSSR(PolicyStatusPage);

// Test url: https://portal-test.thimble.com/smb-link/help-limit-thimble-gl

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import withSSR from '../../components/withSSR';

class LinkPage extends Component {
  static async getInitialData({ match, req, res }) {
    if (match.params.name) {
      let url;

      switch (match.params.name) {
        case 'menu-work':
          url = 'https://thimble.com/in-app/how.html';
          break;
        case 'menu-claim':
          url = 'https://support.thimble.com/support/solutions/articles/42000027718';
          break;
        case 'menu-support':
          url = 'https://support.thimble.com/support/home';
          break;
        case 'menu-about':
          url = 'https://thimble.com/in-app/about.html';
          break;
        case 'menu-contact':
          url = 'https://support.thimble.com/support/tickets/new';
          break;
        case 'menu-terms':
          url = 'https://support.thimble.com/support/solutions/articles/42000015641';
          break;
        case 'menu-privacy':
          url = 'https://support.thimble.com/support/solutions/articles/42000015647';
          break;
        case 'learn-more':
          url = 'https://www.thimble.com/small-business-insurance';
          break;
        case 'cm-feedback':
          url = 'https://heythimble.typeform.com/to/ZIXzvjHp';
          break;
        case 'entertainers':
          url = 'https://support.thimble.com/support/solutions/articles/42000017947';
          break;
        case 'excessively-dangerous':
          url =
            'https://support.thimble.com/support/solutions/articles/42000061092-cgl-what-beauty-services-activities-are-excluded-from-coverage-';
          break;
        case 'terrorism-coverage':
          url =
            'https://support.thimble.com/support/solutions/articles/42000019041-policyholder-disclosure-notice-of-terrorism-insurance-coverage';
          break;
        case 'signature-agreement':
          url =
            'https://support.thimble.com/support/solutions/articles/42000019040-cgl-markel-ersa-and-fraud-statements';
          break;
        case 'terms-conditions':
          url =
            'https://support.thimble.com/support/solutions/articles/42000015641-thimble-s-terms-and-conditions';
          break;
        case 'auto-extend-terms-conditions':
          url =
            'https://support.thimble.com/support/solutions/articles/42000015641-thimble-s-terms-and-conditions';
          break;
        case 'auto-extend-cancel-terms-conditions':
          url =
            'https://support.thimble.com/support/solutions/articles/42000015641-thimble-s-terms-and-conditions';
          break;
        case 'sample-pdf':
          url = 'https://support.thimble.com/support/solutions/articles/42000025281';
          break;
        case 'activity-not-list':
          url =
            'https://support.thimble.com/support/solutions/articles/42000017946-list-of-all-included-activities';
          break;
        case 'ai-extend-coverage':
          url =
            'https://support.thimble.com/support/solutions/articles/42000022327-what-is-an-additional-insured';
          break;
        case 'activity-not-list-construction':
          url =
            'https://support.thimble.com/support/solutions/articles/42000017949-list-of-construction-included-activities';
          break;
        case 'activity-not-list-events-entertainment':
          url =
            'https://support.thimble.com/a/solutions/articles/42000051865-list-of-events-and-entertainment-included-activities-cgl-';
          break;
        case 'activity-not-list-home-garden':
          url =
            'https://support.thimble.com/support/solutions/articles/42000017950-list-of-home-garden-included-activities';
          break;
        case 'activity-not-list-pets':
          url =
            'https://support.thimble.com/support/solutions/articles/42000017953-list-of-pets-included-activities';
          break;
        case 'activity-not-list-professional-services':
          url =
            'https://support.thimble.com/support/solutions/articles/42000017951-list-of-professional-services-included-activities';
          break;
        case 'activity-not-list-handymen-contractors':
          url =
            'https://support.thimble.com/support/solutions/articles/42000051861-list-of-handyman-contractors-included-activities-cgl-';
          break;
        case 'activity-not-list-landscapers':
          url =
            'https://support.thimble.com/a/solutions/articles/42000051867-list-of-landscaper-included-activities-cgl-';
          break;
        case 'activity-not-list-consultants-freelancers':
          url =
            'https://support.thimble.com/a/solutions/articles/42000051948-list-of-consultants-freelancers-included-activities-cgl-';
          break;
        case 'activity-not-list-cleaners-janitors':
          url =
            'https://support.thimble.com/a/solutions/articles/42000051949-list-of-cleaners-janitors-included-activities-cgl-';
          break;
        case 'activity-not-list-beauty-fitness':
          url =
            'https://support.thimble.com/a/solutions/articles/42000051950-list-of-beauty-fitness-included-activities-cgl-';
          break;
        case 'activity-not-list-pet-services':
          url =
            'https://support.thimble.com/a/solutions/articles/42000051947-list-of-pet-services-included-activities-cgl-';
          break;
        case 'verifly-insurance-services':
          url =
            'https://support.verifly.com/support/solutions/articles/42000015654-verifly-producer-licenses-verifly-insurance-services-inc';
          break;
        case 'thimble-insurance-services':
          url =
            'https://support.thimble.com/support/solutions/articles/42000015654-thimble-producer-licenses-thimble-insurance-services-inc';
          break;
        case 'member-discount-find':
          url = 'https://www.thimble.com/help-memberid';
          break;
        case 'member-discount-more':
          url =
            'https://support.thimble.com/support/solutions/articles/42000050809-cgl-how-do-i-get-a-discount-based-on-my-affiliation-with-a-thimble-partner-';
          break;
        case 'partner-plowz':
          url = 'https://app.plowzandmowz.com/onboard';
          break;
        case 'partner-taskeasy':
          url = 'https://www.taskeasy.com/contractors/';
          break;
        case 'partner-lawnguru':
          url = 'https://getlawnguru.com/newprovider/signup';
          break;
        case 'partner-handy':
          url = 'https://www.handy.com/apply';
          break;
        case 'partner-taskrabbit':
          url = 'https://www.taskrabbit.com/become-a-tasker';
          break;
        case 'partner-yelp':
          url = 'https://biz.yelp.com/';
          break;
        case 'partner-amazon':
          url = 'https://services.amazon.com/selling-services/home.htm';
          break;
        case 'partner-thumbtack':
          url = 'https://www.thumbtack.com/pro';
          break;
        case 'partner-homeadvisor':
          url = 'https://www.homeadvisor.com/spa/zip';
          break;
        case 'partner-porch':
          url = 'https://porch.com/pro/';
          break;
        case 'products-liability':
          url =
            'https://support.thimble.com/support/solutions/articles/42000062596-cgl-is-products-liability-insurance-for-vendors-included-in-the-general-liability-policies-arranged';
          break;
        case 'do-you-need-proof-of-insurance':
          url = 'https://support.thimble.com';
          break;
        case 'pause-support':
          url = 'https://support.thimble.com/support/solutions/articles/42000073361';
          break;
        case 'help-events-limit':
          url = 'https://www.thimble.com/help-events-limit';
          break;
        case 'help-hnoa-limit':
          url = 'https://www.thimble.com/hnoa-coverage-limit';
          break;
        case 'help-eb-limit':
          url = 'https://www.thimble.com/eb-coverage-limit';
          break;
        case 'help-ccc-bep-limits':
          url = 'https://www.thimble.com/help-ccc-bep-limits';
          break;
        case 'help-cyber-limit':
          url = 'https://www.thimble.com/what-are-coverage-limits-and-per-deductibles';
          break;
        case 'help-limit-thimble-gl':
          url = 'https://www.thimble.com/help-limit?thimble_gl';
          break;
        case 'help-limit-coterie-gl':
          url = 'https://www.thimble.com/help-limit?coterie_gl';
          break;
        case 'help-limit':
          url = 'https://www.thimble.com/help-limit';
          break;
        case 'help-equipment-limit':
          url = 'https://www.thimble.com/help-equipment-limit';
          break;
        case 'nsic-terrorism-coverage':
          url = 'https://support.thimble.com/support/solutions/articles/42000082891';
          break;
        case 'nsic-ersa':
          url = 'https://support.thimble.com/support/solutions/articles/42000082681';
          break;
        case 'help-annual-payroll':
          url = 'https://www.thimble.com/help-payroll';
          break;
        case 'all-included-event-types':
          url =
            'https://support.thimble.com/support/solutions/articles/42000084524-cgl-list-of-all-included-event-types';
          break;
        case 'nsic-or-ersa':
          url = 'https://support.thimble.com/support/solutions/articles/42000086857';
          break;
        case 'customer-referral-terms-and-conditions':
          url =
            'https://support.thimble.com/support/solutions/articles/42000070128-customer-referral-terms-and-conditions';
          break;
        case 'terms-conditions-of-survey-sweepstakes':
          url =
            'https://support.thimble.com/support/solutions/articles/42000086022-terms-conditions-of-survey-sweepstakes';
          break;
        case 'terms-conditions-of-social-sweepstakes':
          url =
            'https://support.thimble.com/support/solutions/articles/42000086021-terms-conditions-of-social-sweepstakes';
          break;
        case 'nsic-application-states-ersa':
          url =
            'https://help.thimble.com/hc/en-us/articles/4403634296211-National-Specialty-Insurance-Company-Electronic-Record-Signature-Agreement-and-Fair-Credit-Reporting-Act-Notice-App-States';
          break;
        case 'gl-is-primary-and-non-contributory':
          url =
            'https://help.thimble.com/hc/en-us/articles/4401805556755-CGL-Is-the-General-Liability-coverage-arranged-by-Thimble-primary-and-non-contributory-';
          break;
        case 'waiver-of-subrogation':
          url = 'https://www.thimble.com/small-business-insurance/waiver-of-subrogation';
          break;
        case 'contact-support':
          url = 'https://help.thimble.com/hc/en-us/requests/new?ticket_form_id=1500000732181';
          break;
        case 'resume-cancelled':
          url =
            'https://help.thimble.com/hc/en-us/articles/4403612596371-How-do-I-restart-a-canceled-Thimble-Monthly-policy-';
          break;
        default:
          break;
      }

      return {
        url,
      };
    }
  }

  render() {
    return <Redirect to={this.props.url} />;
  }
}

export default withSSR(LinkPage);

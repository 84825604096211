// Test url: https://link-test.thimble.com/pdp/F3XDQD437

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { get } from 'lodash';
import withSSR from '../../components/withSSR';

class ExtPassPolicyDetailPage extends Component {
  static async getInitialData({ match, req, res }) {
    const tokenNo = match.params.token_no;
    if (tokenNo) {
      const options = {
        headers: {
          'X-API': 'api-user',
        },
      };
      const response = await fetch(`${process.env.RAZZLE_API_USER}/user/token/${tokenNo}`, options);

      if (response.status !== 200) {
        throw new Error('User Token Error');
      }

      const userToken = await response.json();
      const token = userToken.token;
      const detailId = get(userToken, 'extra_data.details[0].id');
      const detailLink = detailId ? `&detail_id=${detailId}` : '';
      return {
        url: `${process.env.RAZZLE_WEBAPP_DOMAIN}/ext-pass?token=${token}${detailLink}`,
      };
    }
  }

  render() {
    return <Redirect to={this.props.url} />;
  }
}

export default withSSR(ExtPassPolicyDetailPage);

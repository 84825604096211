import moment from 'moment-timezone';

class PolicyHelper {
  static checksum(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash += char;
    }
    return (hash % 10).toString();
  }

  static convert(json) {
    if (json.policy_status === 'submitted' || json.policy_status === 'declined') {
      throw new Error('Policy Error');
    }

    if (json.is_test) {
      throw new Error('Policy Error');
    }

    let policyStatus = 'active';
    let statusText = 'Active';
    let planName = 'Aviation Liability';

    if (json.app_type === 'smb') {
      planName = 'General Liability';
    }

    switch (json.policy_status) {
      case 'cancelled':
        policyStatus = 'cancelled';
        statusText = 'Cancelled';
        break;
      case 'withdrawn':
        policyStatus = 'withdrawn';
        statusText = 'Cancelled';
        break;
      default:
        if (moment().unix() >= json.coverage_end_timestamp) {
          policyStatus = 'completed';
          statusText = 'Expired';
        } else {
          policyStatus = 'active';
          statusText = 'Active';
        }
    }

    const usdCurrency = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
      currencySign: 'accounting',
      style: 'currency',
      currency: 'USD',
    });
    const data = {
      policy_no: json.policy_no,
      app_type: json.app_type,
      applicant_name: json.applicant_name,
      carrier_name: json.carrier_name,
      company_name: json.applicant_company,
      plan_name: planName,
      status: policyStatus,
      status_text: statusText,
      coverage_limit: usdCurrency.format(json.coverage_maximum_amount ?? 0),
      timezone: json.coverage_timezone,
      start_date_time: moment
        .tz(json.coverage_start_time, json.coverage_timezone)
        .format('M/D/YYYY hh:mm A z'),
      end_date_time: moment
        .tz(json.coverage_end_time, json.coverage_timezone)
        .format('M/D/YYYY hh:mm A z'),
      now_date_time: moment().tz(json.coverage_timezone).format('M/D/YYYY hh:mm A z'),
    };

    if (json.cancel_date_time) {
      data.cancel_date_time = moment
        .tz(json.cancel_date_time, json.coverage_timezone)
        .format('M/D/YYYY hh:mm A z');
    }

    if (policyStatus === 'withdrawn') {
      data.start_date_time = null;
      data.end_date_time = null;
      data.cancel_date_time = null;
    }

    return data;
  }
}

export default PolicyHelper;
